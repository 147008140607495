import React from "react"
import styled from "styled-components"

import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { differenceInSeconds, parse } from "date-fns"

export const secondsPerMinute = 60
export const secondsPerHour = 60 * 60
export const secondsPerDay = 24 * 60 * 60
export const secondsPerMonth = 30 * 24 * 60 * 60

const timeScaling = {
  seconds: {
    duration: secondsPerMinute,
    modulus: secondsPerMinute,
    divider: 1,
    title: "Seconds",
  },
  minutes: {
    duration: secondsPerHour,
    modulus: secondsPerHour,
    divider: secondsPerMinute,
    title: "Minutes",
  },
  hours: {
    duration: secondsPerDay,
    modulus: secondsPerDay,
    divider: secondsPerHour,
    title: "Hours",
  },
  days: {
    duration: secondsPerMonth,
    modulus: secondsPerMonth,
    divider: secondsPerDay,
    title: "Days",
  },
}

const CircleTimerSingle = ({
  size = 240,
  endDateString = "2020-12-01 00:00:00 +02",
  units = "seconds",
  className = "",
}) => {
  const endDateObject = parse(
    endDateString,
    "yyyy-MM-dd HH:mm:ss x",
    new Date()
  )
  const secondsRemaining = differenceInSeconds(endDateObject, new Date())

  const divider = timeScaling[units].divider
  const title = timeScaling[units].title
  let duration = timeScaling[units].duration
  let modulus = timeScaling[units].modulus
  let initialRemainingTime = secondsRemaining % duration

  if (units == "days") {
    // if the countown units is in days, then
    // use whichever is higher: duration or secondsRemaining
    if (duration < secondsRemaining || modulus < secondsRemaining) {
      duration = secondsRemaining
      modulus = secondsRemaining
    }

    // if the units is in days the initialRemainingTime is the total duration
    initialRemainingTime = secondsRemaining
  }

  return (
    <CountdownStyling className={className}>
      <CountdownCircleTimer
        isPlaying
        size={size}
        strokeWidth={3}
        colors="#fd3237"
        trailColor="#dedede"
        duration={duration}
        initialRemainingTime={initialRemainingTime}
        rotation="counterclockwise"
        onComplete={(totalElapsedTime) => [
          secondsRemaining - totalElapsedTime > 0,
          0,
        ]}
      >
        {({ remainingTime }) => {
          return (
            <div className="time-wrapper">
              <div className="time">
                {Math.floor((remainingTime % modulus) / divider)}
              </div>
              <div>{title}</div>
            </div>
          )
        }}
      </CountdownCircleTimer>
    </CountdownStyling>
  )
}

const CountdownStyling = styled.div`
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3rem;
  .time {
    font-size: 4rem;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 1rem;
    .time {
      font-size: 3rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 0.5rem;
    .time {
      font-size: 1.3rem;
    }
  }
`

export default CircleTimerSingle
