import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"

import CircleTimerSingle from "./CircleTimerSingle"

const desktopMinWidth = 1280

import { windowSizes } from "../../utils/responsive"

const DhmsCircleTimer = ({
  size = 240,
  endDateString = "2020-12-01 00:00:00 +02",
}) => {
  const [responsiveSize, setResponsiveSize] = useState(size)
  const [windowWidth, setWindowWidth] = useState(desktopMinWidth)

  // This useLayoutEffect is used to monitor for screen resizes
  // and to update the windowWidth state on resize
  useLayoutEffect(() => {
    if (typeof window == "undefined") {
      return
    }
    setWindowWidth(window.screen.width || window.innerWidth)
    const handleResize = () => {
      setWindowWidth(window.screen.width || window.innerWidth)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // This useLayoutEffect runs when the windowWidth state changes.
  // it is used to calculate the size of the countdown on
  // smaller screens.
  useLayoutEffect(() => {
    if (typeof window == "undefined") {
      return
    }
    let newSize = size
    let tempWindowWidth = window.screen.width || window.innerWidth
    if (tempWindowWidth < desktopMinWidth) {
      if (tempWindowWidth <= windowSizes.mobile) {
        newSize = Math.floor((tempWindowWidth * 0.7) / 4)
      } else if (tempWindowWidth <= windowSizes.tablet) {
        newSize = Math.floor((tempWindowWidth * 0.8) / 4)
      }
    }
    setResponsiveSize(newSize)
  }, [windowWidth])

  return (
    <TimerStyling>
      <CircleTimerSingle
        size={responsiveSize}
        endDateString={endDateString}
        units="days"
      />
      <CircleTimerSingle
        size={responsiveSize}
        endDateString={endDateString}
        units="hours"
      />
      <CircleTimerSingle
        size={responsiveSize}
        endDateString={endDateString}
        units="minutes"
      />
      <CircleTimerSingle
        size={responsiveSize}
        endDateString={endDateString}
        units="seconds"
      />
    </TimerStyling>
  )
}

const TimerStyling = styled.div`
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
  max-width: ${desktopMinWidth}px;
  padding: 0px 40px;
  margin: auto;
`

export default DhmsCircleTimer
